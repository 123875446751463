

 if (document.getElementById("PerSchoolPie")){
var PerSchoolPie = document.getElementById("PerSchoolPie").getContext("2d");
  $.getJSON( "PerSchoolPie")
    .done(function( data ) {
    	//new Chart(cpie).Pie({data:cdata, colors: ["#4D4D4D","#5DA5DA","#FAA43A","#60BD68","#F17CB0","#B2912F","#B276B2","#DECF3F","#F15854"]});
	var colors = [ "#4D4D4D","#5DA5DA","#FAA43A","#60BD68","#F17CB0","#B2912F","#B276B2","#DECF3F","#F15854", "#8B8989","#8C1717","#FFAA00", "#ADFF2F","#79CDCD", "#1D7CF2", "#CD69C9", "#E33638",  "#B276B2","#DECF3F","#F15854", "#8B8989","#8C1717","#FFAA00"];
        var ndata=[];
        var j=0;
	var total=0;
        var other;
	for(var i=0; i < data.length; i++) {
 		data[i].color=colors[i];
		if (data[i].value > 0){
			ndata[j]=data[i];
			ndata[j].color = colors[j];
            j++;

		} else {
			total = total + Number(data[i].value);
		}
        }
	//var t = {value: total, color: "#E33638", label: "Others"};
	//ndata[j] = t;
      //  ndata[other].value = total + ndata[other].value;
	//console.log(ndata);
    	new Chart(PerSchoolPie).Pie(ndata,{responsive: true,
			   	   tooltipTemplate: function (x) {
              					var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						return  x.label + " : " + y ;
				   }
   				  });
    }).fail(function() {
    //console.log( "error" );
    }) .always(function() { });
}


if (document.getElementById("FinSummaryChart")){
    var bdata = {
        labels : ["Jul 17", "Aug 17", "Sep 17", "Oct 17", "Nov 17", "Dec 17", "Jan 18","Feb 18","Mar 18","Apr 18","May 18","Jun 18"],
        //labels : ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"],
        //labels : ["Jan 16","Feb 15","Mar 15","Apr 15","May 15","Jun 15","Jul 15", "Aug 15", "Sep 15", "Oct 15", "Nov 15", "Dec 15"],
        datasets: [
            {
                label: "Earned",
                fillColor: "rgba(220,22,220,0.5)",
                strokeColor: "rgba(220,22,220,0.8)",
                highlightFill: "rgba(220,22,220,0.75)",
                highlightStroke: "rgba(220,22,220,1)",
                data: [0,0,95, 90, 60, 50, 25, 165, 165,0,0,0,0]
                //data: [100,100,100,100,110,100,100,100,100,100,100,100]
            },
            {
                label: "Redeemed",
                fillColor: "rgba(151,187,205,0.5)",
                strokeColor: "rgba(151,187,205,0.8)",
                highlightFill: "rgba(151,187,205,0.75)",
                highlightStroke: "rgba(151,187,205,1)",
                data: [0,0,0, 0, 0, 0 , 0 , 0 ,  0, 0 ,0,0]
                //data: [100,100,100,100,110,100,100,100,100,100,100,100]
            }
        ]

    }
    var FinSummaryChart = document.getElementById("FinSummaryChart").getContext("2d");

  $.getJSON( "FinSummaryChart")
    .done(function( data ) {
			//console.log(data);
		    var bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
		    for(var i=0; i < data["2016"].length; i++) {
		    	bardata[parseInt(data["2016"][i].label,10)-1]=data["2016"][i].value;
		    }
		    
		    //bdata.datasets[0].data=bardata;
		   // console.log(bardata);
		    bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
		    for(var i=0; i < data["2017"].length; i++) {
		    	bardata[parseInt(data["2017"][i].label,10)-1]=data["2017"][i].value;
		    }
		    //bdata.datasets[1].data=bardata;
		    //console.log(bardata);
		    var myChart=new Chart(FinSummaryChart).Bar(bdata, {responsive : true,

			scaleLabel:  function (x) {
                                                        var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        return  y + " $";
                                                     },
                                        tooltipTemplate: function (x) {
                                                                var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                                return  x.label + " : " + y + " $";
                                                        } }); 
		document.getElementById('FinSummaryChart-legend').innerHTML = myChart.generateLegend();
	//console.log( bdata);
    }).fail(function() {
    console.log( "error" );
  })
  .always(function() {
    console.log( "complete" );
  });

}


 if (document.getElementById("LenovoRepairs")){
     var LenovoRepairs = document.getElementById("LenovoRepairs").getContext("2d");
     $.getJSON( "repairstypecount")
         .done(function( data ) {
             //new Chart(cpie).Pie({data:cdata, colors: ["#4D4D4D","#5DA5DA","#FAA43A","#60BD68","#F17CB0","#B2912F","#B276B2","#DECF3F","#F15854"]});
             var colors = [ "#4D4D4D","#5DA5DA","#FAA43A","#60BD68","#F17CB0","#B2912F","#B276B2","#DECF3F","#F15854", "#8B8989","#8C1717","#FFAA00", "#ADFF2F","#79CDCD", "#1D7CF2", "#CD69C9", "#E33638",  "#B276B2","#DECF3F","#F15854", "#8B8989","#8C1717","#FFAA00"];
             var ndata=[];
             var j=0;
             var total=0;
             var other;
             for(var i=0; i < data.length; i++) {
                 data[i].color=colors[i];
                 if (data[i].value > 0){
                     ndata[j]=data[i];
                     ndata[j].color = colors[j];
                     j++;

                 } else {
                     total = total + Number(data[i].value);
                 }
             }
             //var t = {value: total, color: "#E33638", label: "Others"};
             //ndata[j] = t;
             //  ndata[other].value = total + ndata[other].value;
             //console.log(ndata);
             new Chart(LenovoRepairs).Pie(ndata,{responsive: true,
                 tooltipTemplate: function (x) {
                     var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                     return  x.label + " : " + y + " ";
                 }
             });
         }).fail(function() {
         //console.log( "error" );
     }) .always(function() { });
 }
 if (document.getElementById("DevCountpermonth")){
     var bdata1 = {
        labels : ["Jul 17", "Aug 17", "Sep 17", "Oct 17", "Nov 17", "Dec 17", "Jan 18","Feb 18","Mar 18","Apr 18","May 18","Jun 18"],
         //labels : ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"],
         //labels : ["Jan 16","Feb 15","Mar 15","Apr 15","May 15","Jun 15","Jul 15", "Aug 15", "Sep 15", "Oct 15", "Nov 15", "Dec 15"],
         datasets: [
            /*{
                label: "Year 2016",
                fillColor: "rgba(220,22,220,0.5)",
                strokeColor: "rgba(220,22,220,0.8)",
                highlightFill: "rgba(220,22,220,0.75)",
                highlightStroke: "rgba(220,22,220,1)",
                data: [65, 59, 80, 81, 56, 55, 40]
                //data: [100,100,100,100,110,100,100,100,100,100,100,100]
            }
            ,*/
             {
                 label: "Year 2017",
                 fillColor: "rgba(151,187,205,0.5)",
                 strokeColor: "rgba(151,187,205,0.8)",
                 highlightFill: "rgba(151,187,205,0.75)",
                 highlightStroke: "rgba(151,187,205,1)",
                 data: [28, 48, 40, 19, 86, 27, 90]
                 //data: [100,100,100,100,110,100,100,100,100,100,100,100]
             }
         ]

     }


     var DevCountpermonth = document.getElementById("DevCountpermonth").getContext("2d");
     var maxval=0;
     var tval=0;

     $.getJSON("DevCountpermonth")
         .done(function( data ) {
             //console.log(data);
             var bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
             for(var i=0; i < data["previous"].length; i++) {
                
                var ind=parseInt(data["previous"][i].label,10);
                 if (ind >=7){
                        ind = ind-7;
                } else {
                        ind = ind+5;
                }
                 bardata[ind]=data["previous"][i].value;
                 tval=parseInt(data["previous"][i].value);
                 if (tval> maxval){
                     maxval=tval;
                 }

             }

             bdata1.datasets[0].data=bardata;
             bdata1.datasets[0].label=data['label'][0];
             /*
             // console.log(bardata);
             bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
             for(var i=0; i < data["current"].length; i++) {
                 bardata[parseInt(data["current"][i].label,10)-1]=data["current"][i].value;
                 tval = parseInt(data["current"][i].value);
                 if (tval> maxval){
                     maxval=tval;
                 }
             }
             bdata1.datasets[1].data=bardata;
             bdata1.datasets[1].label=data['label'][1];
             
             console.log(bardata);
             */
             var myChart=new Chart(DevCountpermonth).Bar(bdata1, {responsive : true,

                 scaleLabel:  function (x) {
                     var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                     return  " " + y + " " ;
                 },
                 tooltipTemplate: function (x) {
                     var y = Number(x.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                     return  x.label + " : " + "  "+ y + "  " ;
                 } });
             //document.getElementById('DevCountpermonth-legend').innerHTML = myChart.generateLegend();

         }).fail(function() {
             console.log( "error" );
         })
         .always(function() {
             console.log( "complete" );
         });

 }


 if (document.getElementById("ClaimCountpermonth")){
    var bdata5 = {
       labels : ["Jul 17", "Aug 17", "Sep 17", "Oct 17", "Nov 17", "Dec 17", "Jan 18","Feb 18","Mar 18","Apr 18","May 18","Jun 18"],
        //labels : ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"],
        //labels : ["Jan 16","Feb 15","Mar 15","Apr 15","May 15","Jun 15","Jul 15", "Aug 15", "Sep 15", "Oct 15", "Nov 15", "Dec 15"],
        datasets: [  
           {
               label: "Accidental Damage Claims",
               fillColor: "#FF4444",
               strokeColor: "#FF4444",
               highlightFill: "#FF4444",
               highlightStroke: "#FF4444",
               data: [0,0,0,0,0,0,0,0,0,0,0,0]
               //data: [100,100,100,100,110,100,100,100,100,100,100,100]
           }
           ,
            {
                label: "Customer Limited Warranty",
                fillColor: "#5DA5DA",
                strokeColor: "#5DA5DA",
                highlightFill: "#5DA5DA",
                highlightStroke: "#5DA5DA",
                data: [0,0,0,0,0,0,0,0,0,0,0,0]
                //data: [100,100,100,100,110,100,100,100,100,100,100,100]
            },
            {
                label: "Labor Only Claims",
                fillColor: "#FAA43A",
                strokeColor: "#FAA43A",
                highlightFill: "#FAA43A",
                highlightStroke: "#FAA43A",
                data: [0,0,0,0,0,0,0,0,0,0,0,0]
                //data: [100,100,100,100,110,100,100,100,100,100,100,100]
            }
            ,
             {
                 label: "Non Warranty",
                 fillColor: "#60BD68",
                 strokeColor: "#60BD68",
                 highlightFill: "#60BD68",
                 highlightStroke: "#60BD68",
                 data: [0,0,0,0,0,0,0,0,0,0,0,0]
                 //data: [100,100,100,100,110,100,100,100,100,100,100,100]
             }
        ]

    }



 var ClaimCountpermonth = document.getElementById("ClaimCountpermonth").getContext("2d");
 var maxval=0;
 var tval=0;

 $.getJSON("ClaimCountpermonth")
     .done(function( data ) {
         //console.log(data);
         var bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
         for(var i=0; i < data["previous"].length; i++) {
            
            var ind=parseInt(data["previous"][i].label,10);
             if (ind >=7){
                    ind = ind-7;
            } else {
                    ind = ind+5;
            }
             //bardata[ind]=data["previous"][i].value;
             bdata5.datasets[0].data[ind]=data["previous"][i].ADW
             bdata5.datasets[1].data[ind]=data["previous"][i].CLW
             bdata5.datasets[2].data[ind]=data["previous"][i].LAO
             bdata5.datasets[3].data[ind]=data["previous"][i].NOW
             

         }

         var myClaimChart=new Chart(ClaimCountpermonth).Bar(bdata5, {responsive : true});
         document.getElementById('ClaimCountpermonth-legend').innerHTML = myClaimChart.generateLegend();

     }).fail(function() {
         console.log( "error" );
     })
     .always(function() {
         console.log( "complete" );
     });

}
 if (document.getElementById("cbar6")){
     var bdata1 = {
         labels : ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"],
         //labels : ["Jan 16","Feb 15","Mar 15","Apr 15","May 15","Jun 15","Jul 15", "Aug 15", "Sep 15", "Oct 15", "Nov 15", "Dec 15"],
         datasets: [
             {
                 label: "Year 2016",
                 fillColor: "rgba(151,187,205,0.5)",
                 strokeColor: "rgba(151,187,205,0.8)",
                 highlightFill: "rgba(151,187,205,0.75)",
                 highlightStroke: "rgba(151,187,205,1)",
                 data: [28, 48, 40, 19, 86, 27, 90]
                 //data: [100,100,100,100,110,100,100,100,100,100,100,100]
             }
         ]

     }
     var cbar6 = document.getElementById("cbar6").getContext("2d");
     var maxval=0;
     var tval=0;

     $.getJSON( "lenovocount")
         .done(function( data ) {
             //console.log(data);

             bardata=[0,0,0,0,0,0,0,0,0,0,0,0];
             for(var i=0; i < data["2016"].length; i++) {
                 bardata[parseInt(data["2016"][i].label,10)-1]=data["2016"][i].value;
                 tval = parseInt(data["2016"][i].value);
                 if (tval> maxval){
                     maxval=tval;
                 }
             }
             bdata1.datasets[0].data=bardata;
             console.log(bardata);
             var myChart=new Chart(cbar6).Bar(bdata1, {responsive : true });
             

         }).fail(function() {
             console.log( "error" );
         })
         .always(function() {
             console.log( "complete" );
         });

 }
